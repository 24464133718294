markers = [];
markerCluster = null;
initialOpen = true;
mapContainer = null;

;(function($, window, document, undefined) {
  var $win = $(window);
  var $doc = $(document);

  $.fn.equalizeHeight = function() {
    var hght = 0;
    var $this = this;

    $this.outerHeight('auto');

    $this.each(function(){
      var itemHght = $(this).outerHeight();

      if (itemHght > hght) {
        hght = itemHght;
      }
    });

    $this.outerHeight(hght);
  };

  //Default Map
  function initMultipleMaps() {
    $('#map-default').each(function(index, Element){
      var center = $('#map-default').data('center');

      var myOptions = {
        zoom: 16,
        center: center
      };

      map = new google.maps.Map(Element, myOptions);

      var marker = new google.maps.Marker({
        position: center,
        map: map
      });

      google.maps.event.addDomListener(window, 'resize', function() {
        map.setCenter(center);
      });
    });
  }

  // Load AJAX data
  function loadAjax(filePath) {
    return $.ajax({
      'async': false,
      'global': false,
      'url': filePath
    }).responseJSON;
  }

  google.maps.Map.prototype.setCenterWithOffset= function(latlng, offsetX, offsetY) {
    var map = this;
    var ov = new google.maps.OverlayView();
    ov.onAdd = function() {
      var proj = this.getProjection();
      var aPoint = proj.fromLatLngToContainerPixel(latlng);
      aPoint.x = aPoint.x+offsetX;
      aPoint.y = aPoint.y+offsetY;
      map.setCenter(proj.fromContainerPixelToLatLng(aPoint));
    };
    ov.draw = function() {};
    ov.setMap(this);
  };

  function getInfoBoxYoffset($map) {
    var mapHeight = $map.outerHeight();
    var infoBoxHeight = $map.find('.infoBox').outerHeight();

    return -1 * ((infoBoxHeight + 40) / 2);
  }

  function addMapData(response, map){
      var mapData = response;
      var iBox;
      var markersLength = mapData.markers.length;
      var content;
      var clusterIsEnabled = mapData.cluster;

      var clusterOptions = {
          styles: [{
              fontWeight: 'normal',
              fontFamily: 'Roboto, sans-serif',
              textColor: 'white',
              height: 30,
              url: "/wp-content/uploads/cluster-lb.png",
              width: 30,
              textSize: 16
          }]
      };

      map.addListener('click', function(event){
          iBox.close();

          for(var i = 0; i < markers.length; i++){
              markers[i].iBoxOpen = false;
          }
      });

      for(var i = 0; i < markersLength; i++){
          if(clusterIsEnabled){
              if(mapData.markers[i].infobox.title == 'Sold'){
                  content = '<div class="infobox info-sold"><div class="infobox__image"><img src="' + mapData.markers[i].infobox.popupImg + '"/></div> <div class="infobox__body"><h6>' + mapData.markers[i].infobox.title + '</h6><h2>' + mapData.markers[i].infobox.popupTitle + '</h2><p>' + mapData.markers[i].infobox.popupText + '</p></div>';
              } else {
                  content = '<a href="' + mapData.markers[i].infobox.link + '"><div class="infobox"><div class="infobox__image"><img src="' + mapData.markers[i].infobox.popupImg + '"/></div> <div class="infobox__body"><h6>' + mapData.markers[i].infobox.title + '</h6><h2>' + mapData.markers[i].infobox.popupTitle + '</h2><p>' + mapData.markers[i].infobox.popupText + '</p></div></a>';
              }

              marker = new google.maps.Marker({
                  position: mapData.markers[i].position,
                  map: map,
                  content: content,
                  iBoxOpen: false,
                  icon: mapData.markers[i].icon
              });

              iBox = new InfoBox();

              marker.addListener('click', function(){
                  for(var i = 0; i < markers.length; i++){
                      markers[i].iBoxOpen = false;
                  }

                  if(this.iBoxOpen == false){
                      iBox.setContent(this.content);
                      iBox.open(map, this);

                      this.iBoxOpen = true;

                      var pos = this.position;

                      if(!initialOpen){
                          setTimeout(function(){
                              map.setCenterWithOffset(pos, 0, getInfoBoxYoffset(mapContainer));
                          }, 500);
                      } else {
                          initialOpen = false;
                      }
                  }
              });
          } else {
              marker = new google.maps.Marker({
                  position: mapData.markers[i].position,
                  map: map,
                  icon: mapData.markers[i].icon
              });
          }

          markers.push(marker);
      }

      if(clusterIsEnabled){
          markerCluster = new MarkerClusterer(map, markers, clusterOptions);

          new google.maps.event.trigger(markers[0], 'click');

          map.setCenter({
              'lat': 31.5,
              'lng': -99.5
          })
      }
  }

  function clearMapData(response, map){
      google.maps.event.trigger(map, 'click');

      markerCluster.clearMarkers();
      markerCluster = null;

      for(var i = 0; i < markers.length; i++){
          markers[i].setMap(null);
      }
      markers = [];
  }

  function renderProperties(response){
      var properties = response.markers;
      var on_the_market = [];
      var under_contract = [];
      var sold = [];

      var $properties_container = $('#properties .container');
      $properties_container.html('');

      $.each(properties, function(idx, ele){
          switch(ele.infobox.title){
              case 'Active':
                  on_the_market.push(ele);
                  break;
              case 'Under Contract':
                  under_contract.push(ele);
                  break;
              case 'Sold':
                  sold.push(ele);
                  break;
          }
      });

      if(under_contract.length){
          on_the_market = on_the_market.concat(under_contract);
      }

      if(on_the_market.length){
          var $container = $('<div class="section__group">' +
              '<header class="section__head">' +
                '<h1>On the Market</h1>' +
              '</header>' +
              '<div class="section__body">' +
                '<div class="articles-primary">' +
                  '<div class="row"></div>' +
                '</div>' +
              '</div></div>');
          var $row = $container.find('.row');

          $.each(on_the_market, function(idx, ele){
              var $item = $('<div class="col-md-4 col-sm-4 property-small">' +
                    '<div class="article-primary active">' +
                      '<div class="article__image">' +
                        '<img src="' + ele.infobox.image + '">' +
                      '</div>' +
                      '<div class="article__content">' +
                        '<h6>' + ele.infobox.status + '</h6>' +
                        '<h2></h2>' +
                        '<ul>' +
                        '<li><strong>Location:</strong> ' + ele.infobox.location + '</li>' +
                        '<li><strong>Year Built:</strong> ' + ele.infobox.year_built + '</li>' +
                        '<li><strong>Units:</strong> ' + ele.infobox.units + '</li>' +
                        '</ul>' +
                      '</div>' +
                      '<div class="article__actions">' +
                      '</div>' +
                    '</div>' +
                  '</div>');
              var $title = $item.find('h2');
              var $actions = $item.find('.article__actions');

              if(ele.infobox.status == 'Active'){
                  $title.append('<a href="' + ele.infobox.link + '">' + ele.infobox.popupTitle + '</a>');

                  if(ele.infobox.file){
                      $actions.append('<a href="' + ele.infobox.file + '" class="btn" target="_blank">View Offering Memorandum &amp; Financials</a>')
                  }

                  $actions.append('<a href="' + ele.infobox.link + '" class="article__link">View Details</a>');
              } else {
                  $title.text(ele.infobox.popupTitle);
              }

              $row.append($item);
          });

          $properties_container.append($container);
      }

      if(sold.length){
          var $container = $('<div class="section__group">' +
              '<header class="section__head">' +
                '<h1>Recent Sales</h1>' +
              '</header>' +
              '<div class="section__body">' +
                '<div class="articles-primary articles-primary--small">' +
                  '<div class="row"></div>' +
                '</div>' +
              '</div></div>');
          var $row = $container.find('.row');

          $.each(sold, function(idx, ele){
              var $item = $('<div class="col-md-3 col-sm-4 col-xs-6 property-small">' +
                  '<div class="article-primary article-primary--small">' +
                    '<div class="article__image">' +
                      '<img src="' + ele.infobox.image + '">' +
                    '</div>' +
                    '<div class="article__content">' +
                      '<h6>' + ele.infobox.status + '</h6>' +
                      '<h2>' + ele.infobox.popupTitle + '</h2>' +
                      '<ul>' +
                        '<li><strong>Location:</strong> ' + ele.infobox.location + '</li>' +
                        '<li><strong>Year Built:</strong> ' + ele.infobox.year_built + '</li>' +
                        '<li><strong>Units:</strong> ' + ele.infobox.units + '</li>' +
                      '</ul>' +
                    '</div>' +
                  '</div></div>');

              $row.append($item);
          });

          $properties_container.append($container);
      }

      if(!on_the_market.length && !sold.length){
          $properties_container.append('<p style="text-align: center;">There are no properties that match the given requirements</p>' +
              '<p style="text-align: center;"><a href="#" class="btn btn--border btn-clear">Clear Filter</a></p>');
      }
  }

  // Function to initialize maps
    function startMaps(){
        $('[id^="map"]').each(function(){
            var $map = $(this);
            var response = loadAjax($map.data('mapdata'));

            mapContainer = $map;
            map = new google.maps.Map($map.get(0), response.mapOptions);
            addMapData(response, map);
        });

        $('.unfiltered-property-map').each(function(){
            var $map = $(this);
            $.ajax({
                type: 'post',
                url: ajaxurl,
                data: {
                    'action': 'get_property_json',
                },
                dataType: 'json',
                success: function(response){
                    mapContainer = $map;
                    map = new google.maps.Map($map.get(0), response.mapOptions);
                    addMapData(response, map);
                }
            });
        });

        $('.property-map').each(function(){
            var $map = $(this);
            $.ajax({
                type: 'post',
                url: ajaxurl,
                data: $('#property-filter-form').serialize() + '&action=get_property_json',
                dataType: 'json',
                success: function(response){
                    mapContainer = $map;
                    map = new google.maps.Map($map.get(0), response.mapOptions);
                    addMapData(response, map);
                    renderProperties(response);
                }
            });
        });
    }

  var stepX = 77 / 14;

  function drawGrid(graph, int) {
    var g = graph.g();
    g.attr('id', 'grid'+int);
    for (i = 0; i <= stepX + 2; i++) {
      var horizontalLine = graph.path(
          "M" + 0 + "," + stepX * i + " " +
          "L" + 77 + "," + stepX * i);
      horizontalLine.attr('class', 'horizontal');
      g.add(horizontalLine);
    }
    for (i = 0; i <= 14; i++) {
      var horizontalLine = graph.path(
          "M" + stepX * i + "," + 38.7 + " " +
          "L" + stepX * i + "," + 0);
      horizontalLine.attr('class', 'vertical');
      g.add(horizontalLine);
    }
  }

  function drawCircle(container, id, progress, parent, classname) {
    var paper = Snap(container);
    var prog = paper.path("M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50");
    var lineL = prog.getTotalLength();
    var oneUnit = lineL/100;
    var toOffset = lineL - oneUnit * progress;
    var myID = 'circle-graph-'+id;


    prog.attr({
      'stroke-dashoffset':lineL,
      'stroke-dasharray':lineL,
      'id':myID,
      'class': classname
    });

    var animTime = 1300;

    prog.animate({
      'stroke-dashoffset':toOffset
    },animTime,mina.easein);

    function countCircle(animtime,parent,progress){
      var textContainer = $(container).closest(parent).find('.circle-percentage span');
      var i = 0;
      var time = 1300;
      var intervalTime = Math.abs(time / progress);
      var timerID = setInterval(function () {
        i++;
        textContainer.text(i+"%");
        if (i === progress) clearInterval(timerID);
      }, intervalTime);
    }

    drawGrid(paper, id);

    countCircle(animTime,parent,progress);
  }

  //Animate Sections
  var $animated = $('.animated');

  var winHeight = $win.height();
  var winWidth = $win.width();
  var winScroll = $win.scrollTop();

  function animateElements() {
    winScroll = $win.scrollTop();

    if ( $animated.length === 0 ) {
      return;
    }
  }


  function animate(winST) {
    $('.animate').each(function(){
      var $this = $(this);

      if (winST + ($win.outerHeight() / 1.3) > $this.offset().top) {
        $this.addClass('animated');
      } else if (winST + ($win.outerHeight() / 2) < $this.offset().top) {
        $this.removeClass('animated');
      }
    });
  }


  $doc.ready(function() {
    //Button Burger
    $('.btn-burger').on('click', function(e) {
      e.preventDefault();

      $(this).toggleClass('active');
      $('.wrapper').toggleClass('active');
    });

    $('.range-slider').each(function(){
      var $this = $(this);
      var $parent = $this.closest('.range');
      var $checkbox = $parent.siblings('ul').find('input');
      var $checkboxLabel = $checkbox.next('label');
      var $overlay = $parent.find('.range-overlay');
      var dataVal = $this.data('values');
      var dataStep;
      var dataCurrent;
      var display = $parent.find('h5');
      var $start = $parent.find('.field-start');
      var $end = $parent.find('.field-end');

      if($checkbox.is(':checked')){
          $overlay.hide();
      }

      $checkbox.change(function(){
          if($checkbox.is(':checked')){
              $overlay.hide();
              $checkboxLabel.text('Deselect');
          } else {
              $overlay.show();
              $checkboxLabel.text('Select');
          }
      });

      if($this.data('step')){
          dataStep = $this.data('step');
      } else {
          dataStep = 1;
      }

      if($this.data('current')){
          dataCurrent = $this.data('current');
      } else {
          dataCurrent = dataVal;
      }

      $this.slider({
        range: true,
        min: dataVal[0],
        max: dataVal[1],
        step: dataStep,
        values: dataCurrent,
        slide: function(e, ui){
          if(ui.values[1] == 500){
            display.text(ui.values[0] + '-' + ui.values[1] + '+');
          } else {
            display.text(ui.values[0] + '-' + ui.values[1]);
          }

          if(ui.values[0] != dataVal[0] || ui.values[1] != dataVal[1]){
            $start.val(ui.values[0]);
            $end.val(ui.values[1]);
          }
        }
      });

      $this.draggable();
    });


    $('.js-toggle').on('click', function(e) {
      e.preventDefault();

      $(this).toggleClass('open');

      $($(this).data('target')).slideToggle();
    });

    $('body').on('click', '.js-popup', function(e) {
      e.preventDefault();
      link = $(this).attr('href');
      p = link;
      $('#input_2_6').val(p);
      $.magnificPopup.open({
        removalDelay: 100,
        mainClass: 'mfp-fade',
        items: {
          src: '#popup',
          type: 'inline'
        }
      })
    });

  $('body').on('click', '.js-popup-alt', function(e) {
      e.preventDefault();
      link = $(this).attr('href');
      p = link;
      $('#input_2_6').val(p);
      $.magnificPopup.open({
          removalDelay: 100,
          mainClass: 'mfp-fade',
          items: {
              src: '#popupreports',
              type: 'inline'
          }
      })
  });

    $('.btn-clear').click(function(e) {
        $('#clear-filter').click();
    });

    $('#apply-filter').click(function(e){
        e.preventDefault();
        $('#property-filter-form').submit();
    });

    $('#clear-filter').click(function(e){
        e.preventDefault();

        var $form = $('#property-filter-form');
        $form.find('input[type="checkbox"]').prop('checked', false);
        $form.find('input[name="status[]"]').prop('checked', true);

        $('.range-overlay').show();

        $('.range-slider').each(function(){
            var $this = $(this);
            var $parent = $this.closest('.range');
            var dataVal = $this.data('values');
            var display = $parent.find('h5');
            var $start = $parent.find('.field-start');
            var $end = $parent.find('.field-end');

            $this.slider('values', 0, dataVal[0]);
            $this.slider('values', 1, dataVal[1]);

            $start.val('');
            $end.val('');

            display.text(dataVal[0] + '-' + dataVal[1]);
        });

        $form.submit();
    });

      $('#property-filter-form').submit(function(e){
          e.preventDefault();

          $.ajax({
              type: 'post',
              url: ajaxurl,
              data: $('#property-filter-form').serialize() + '&action=get_property_json',
              dataType: 'json',
              success: function(response){
                  window.history.pushState({},'',location.protocol + '//' + location.host + location.pathname + '?' + $('#property-filter-form').serialize());

                  clearMapData(response, map);
                  addMapData(response, map);
                  renderProperties(response);
              }
          });
      })
  });

  $win.on('load', function() {
      if ($win.width() >= 768) {
          $('.js-toggle').click();
      }

    animateElements();

    if ($('#map').length || $('.property-map').length || $('.unfiltered-property-map').length) {
      startMaps();
    }

    if ($('#map-default').length) {
      initMultipleMaps();
    }

    var sliderAnimation =  $('.slider--secondary').data('animation');


    if(sliderAnimation) {
      $('.slider--secondary .slider__slides')
          .addClass('owl-carousel')
          .owlCarousel({
            animateOut: sliderAnimation,
            items: 1,
            loop: true,
            smartSpeed: 500,
            dots: false,
            nav: true,
            navText: [
              '<i class="ico-angle-left-blue"></i>',
              '<i class="ico-angle-right-blue"></i>'
            ]
          });

    } else {
      $('.slider--secondary .slider__slides')
          .addClass('owl-carousel')
          .owlCarousel({
            items: 1,
            loop: true,
            smartSpeed: 500,
            dots: false,
            nav: true,
            navText: [
              '<i class="ico-angle-left-blue"></i>',
              '<i class="ico-angle-right-blue"></i>'
            ]
          });
    }

    $('.slider--testimonials .slider__slides')
        .addClass('owl-carousel')
        .owlCarousel({
          margin: 30,
          loop: true,
          smartSpeed: 500,
          dots: true,
          navText: [
            '<i class="ico-angle-left"></i>',
            '<i class="ico-angle-right"></i>'
          ],
          responsive: {
            0: {
              items: 1,
              autoHeight: true,
              nav: false
            },
            768: {
              items: 2,
              autoHeight: false,
              nav: true
            },
            1023: {
              items: 3,
              nav: true
            }
          }
        });

    $('.slider--primary .slider__slides')
        .addClass('owl-carousel')
        .owlCarousel({
          items: 1,
          loop: true,
          dots: false,
          smartSpeed: 500,
          nav: true,
          navText: [
            '<i class="ico-angle-left"></i>',
            '<i class="ico-angle-right"></i>'
          ]
        });

    $('.slider-cities .slider__slides')
        .addClass('owl-carousel')
        .owlCarousel({
          items: 1,
          loop: true,
          dots: true,
          smartSpeed: 500,
          dotsData: true,
          autoplay: true,
          autoplayTimeout: 4000
        });

    $('.slider--clients .slider__slides')
        .addClass('owl-carousel')
        .owlCarousel({
          autoplay:true,
          autoplayTimeout:3000,
          autoplayHoverPause:false,
          margin: 30,
          loop: true,
          dots: false,
          smartSpeed: 500,
          nav: true,
          navText: [
            '<i class="ico-angle-left-gray"></i>',
            '<i class="ico-angle-right-gray"></i>'
          ],
          responsive: {
            0: {
              items: 1
            },
            767: {
              items: 3
            },
            1023: {
              items: 4
            }
          }
        });

  }).on('load resize', function() {

    if ($win.width() > 760) {
      setTimeout(function() {
        $('.testimonial').equalizeHeight();
      }, 100);

    }

    if ($win.width() > 767) {
      winH = $win.height();
      winW = $win.width();

      if ($('.feature-primary').length) {
        $('.feature-primary').equalizeHeight();
      }

      if ($('.article').length) {
        $('.article').equalizeHeight();
      }
    }


  }).on('scroll', function(){
    var scrolledY = $win.scrollTop();
    $('.parallax').css('background-position', 'center ' + ((scrolledY*.3)) + 'px');

    if($win.width() > 767) {
      $('.header').css('transform', 'translateY(' + (scrolledY*.3) + 'px)');

      animate(scrolledY);
    }

  }).on('load scroll', function() {
    $('.header').toggleClass('fixed', $win.scrollTop() > 0);

    if($('.circle').length) {
      var circleOffsetTop = $('.circle').offset().top;
      var circlesHeight = $('.stats').outerHeight();
      var winST = $win.scrollTop();
      var viewPort = (winST + $win.height()) * 0.67;

      if( viewPort > circleOffsetTop && (circleOffsetTop + circlesHeight) > winST ) {
        $('.circle').each(function() {
          var $circle = $(this);
          var chart = $circle.find('.chart-circle').attr('class');
          var percent = $circle.data('percent');
          var dataID = $circle.data('id');
          var pathClassname = $circle.data('classname') || 'chart-circle__path';

          drawCircle('[data-id="' + dataID + '"] .chart-circle', dataID, percent, '.' + $(this).attr('class'), pathClassname);
        });
      }
    }
  });
})(jQuery, window, document);